import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class Menu extends Abstract {
    constructor(session) {
        super('security/Menu', session);
        this.parent = null;
        this.name = null;
        this.icon = null;
        this.url = null;
        this.actions = []
    }

    async getByUser(user) {
        let response = await axios.get(this.controller + '/' + user);
        return response.data;
    }

    async saveActions(actions) {
        let response = await axios.post(this.controller + '/actions/' + this.id,
        actions, {
            headers: {
                user: this.user
            }
        })
        return response;
    }

    async getActions() {
        let response = await axios.post(this.controller + '/actions/' + this.id)
        return response;
    }
}
