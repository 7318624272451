<template>
	<div class="exception-body notfound">
		<div class="exception-panel">
			<h1>404</h1>
			<h3>no encontrado</h3>
			<p>La pagina que estas buscando no existe</p>
			<router-link to="/">
				<Button type="button" label="Regresar al inicio"></Button>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		color() {
			if (this.$appState.colorScheme === 'light') return 'dark';
			return 'light';
		}
	}
}
</script>

<style scoped>

</style>
