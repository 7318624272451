<template>
	<div class="exception-body accessdenied">
		<div class="exception-panel">
			<h1>ACCESO</h1>
			<h3>DENEGADO</h3>
			<p>No tienes permitido visualizar esta pantalla</p>
			<router-link to="/">
				<Button type="button" label="Ir al inicio"></Button>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		color() {
			if (this.$appState.colorScheme === 'light') return 'dark';
			return 'light';
		}
	}
}
</script>

<style scoped>

</style>
