import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class ERPUserAccess extends Abstract {
    constructor(session) {
        super('security/ERPUser', session);
        this.id_branch = null;
        this.id_company = null;
        this.id_user = null;
    }
}

export class ERPUser extends Abstract {
    constructor(session) {
        super('security/ERPUser', session);
        this.username = null;
        this.password = null;
        this.name = null;
        this.lastname = null;
        this.email = null;
        this.id_erp_profile = null;
        this.is_active = true;
        this.is_api_user = true;
        this.accesses = []
    }

    async sendReset(params) {
        let response = await axios.post(this.controller + '/reset', params);
        return response.data;
    }

    async sendConfirm(session) {
        let response = await axios.post(this.controller + '/confirm/send/' + this.username, null, {
            headers: {
                user: session.user,
                company: session.company,
                branch: session.branch
            }
        });
        return response.data;
    }


    async confirm(id, params) {
        let response = await axios.put(this.controller + '/confirm/' + id, params);
        return response.data;
    }

    async confirmReset(id, params) {
        let response = await axios.put(this.controller + '/confirm/reset/' + id, params);
        return response.data;
    }

    async login() {
        let response = await axios.post(this.controller + '/login', {
            username: this.username,
            password: this.password
        });
        return response.data;
    }

    async resetData(id) {
        let response = await axios.get(this.controller + '/reset/' + id);
        return response.data;
    }

    async register(id) {
        let response = await axios.get(this.controller + '/register/' + id);
        return response.data;
    }

    async access() {
        let response = await axios.get(this.controller + '/accesos/' + this.username);
        return response.data;
    }

    async retrieveByUsername() {
        let response = await axios.get(this.controller + '/username/' + this.username);
        return response.data;
    }

    async updateInfo() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para actualizar"
        let response = await axios.put(this.controller + "/info/" + this.username, this, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async updatePassword(password) {
        let response = await axios.put(this.controller + "/password/" + this.username, {
            password: password
        }, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }
    
}
