<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style>
  body::-webkit-scrollbar {
	width: 5px;
	}

	/* Track */
body::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px;
	}

	/* Handle */
body::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
	}

	/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
	background: #555;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
	}
</style>
