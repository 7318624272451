<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <Loader v-model="loading" />
        <Toast />
        <Dialog :style="{ width: '450px' }" header="Acceso" :modal="true" v-model:visible="dialogAcceso">
            <div class="p-fluid">
                <div class="field">
                    <label for="firstname">Empresa</label>
                    <Dropdown v-model="acceso.empresa" :options="empresas" optionLabel="company_name" optionValue="id_company" />
                </div>
                <div class="field">
                    <label for="lastname">Sucursal</label>
                    <Dropdown v-model="acceso.sucursal" :options="sucursales" optionLabel="branch_name" optionValue="id_branch" />
                </div>
                <div class="field">
                    <Button :disabled="!acceso.empresa || !acceso.sucursal" type="button" label="Ingresar" @click="ingresar"></Button>
                </div>
            </div>
        </Dialog>
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <!-- <div class="col-12 mt-5 xl:mt-0 text-center">
                            <img :src="'layout/images/pages/login-' + loginColor + '.png'" alt="Sakai logo" class="mb-5" style="width:100px; height:200px;">
            </div> -->
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--bluegray-200), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <img :src="'layout/images/pages/login-' + loginColor + '.png'" alt="Image" height="50" class="mb-3">
                        <div class="text-900 text-3xl font-medium mb-3">
                            <!-- <img :src="'layout/images/pages/app-login.png'" alt="Image" height="25"> -->
                            <!-- {{app_name}} -->
                        </div>
                        <!-- <span class="text-600">{{ app_client }}</span><br> -->
                        <span class="text-600 font-medium">Identificate para continuar</span>
                    </div>
                
                    <div class="w-full md:w-10 mx-auto">
                        <label for="email1" class="block text-900 text-xl font-medium mb-2">Usuario</label>
                        <InputText id="email1" v-model="usuario.username" type="text" class="w-full mb-3" placeholder="User" style="padding:1rem;" />
                
                        <label for="password1" class="block text-900 font-medium text-xl mb-2">Contraseña</label>
                        <Password id="password1" v-model="usuario.password"  placeholder="Password" :feedback="false" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
                
                        <div class="flex align-items-center justify-content-between mb-5">
                            <div class="flex align-items-center">
                                <Checkbox id="rememberme1" v-model="usuario.remember" :binary="true" class="mr-2"></Checkbox>
                                <label for="rememberme1">¿Recordarme?</label>
                            </div>
                            <a @click="resetPassword" class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">¿Olvidaste tu contraseña?</a>
                        </div>
                        <Button @click="login" label="Ingresar" class="w-full p-3 text-xl"></button>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ERPUser } from '../models/seguridad/ERPUser.js';
import Loader from '../components/general/Loader.vue';
import { ErrorToast, fillMenuDb } from '../utilities/General.js';

export default {
    data() {
        return {
            usuario: new ERPUser(),
            loading: false,
            dialogAcceso: false,
            acceso: {
                empresa: null,
                sucursal: null,
            },
            accesos: [],
            empresas: [],
            sucursales: [],
            app_creator: null
        };
    },
    components: {
        Loader,
    },
    watch: {
        ['acceso.empresa'](newValue) {
            this.sucursales = this.accesos.filter((x) => {
                if (x.id_company == newValue) {
                    return x;
                }
            });
        },
        dialogAcceso(newValue) {
            if (!newValue) {
                this.accesos = [];
                this.empresas = [];
                this.sucursales = [];
                this.acceso.empresa = null;
                this.acceso.sucursal = null;
            }
        },
    },
    methods: {
        resetPassword() {
            this.$router.push('/resetpassword');
        },
        replaceLink(linkElement, href, callback) {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);

                if (callback) {
                    callback();
                }
            });
        },
        changeStyleSheetUrl(id, value, from) {
            const element = document.getElementById(id);
            const urlTokens = element.getAttribute('href').split('/');

            if (from === 1) {
                // which function invoked this function - change scheme
                urlTokens[urlTokens.length - 1] = value;
            } else if (from === 2) {
                // which function invoked this function - change color
                urlTokens[urlTokens.length - 2] = value;
            }

            const newURL = urlTokens.join('/');

            this.replaceLink(element, newURL);
        },
        async ingresar() {
            //Agregamos session
            
            if (this.usuario.remember) {
                this.$storage.setStorageSync('usuario', this.usuario.username);
                this.$storage.setStorageSync('company', this.acceso.empresa);
                this.$storage.setStorageSync('branch', this.acceso.sucursal);
            }else{
                //TOMAMOS CUANTO DURARA LA SESSION EN HORAS
                let milliseconds = parseInt(process.env.VUE_APP_SESSION_HOURS_DURATION) * 60 * 60000;
                this.$storage.setStorageSync('usuario', this.usuario.username, milliseconds);
                this.$storage.setStorageSync('company', this.acceso.empresa, milliseconds);
                this.$storage.setStorageSync('branch', this.acceso.sucursal, milliseconds);
            }
            let branch_name = this.sucursales.find((x) => x.id_branch == this.acceso.sucursal).branch_name;
            this.$storage.setStorageSync('branch_name', branch_name);
            //* EJECUTAMOS CARGA DE MENU
            try {
                this.loading = true;
                await fillMenuDb(this.usuario.username);
                //* Usuario maestro que puede ver otras API's
                //* Con el token, esto será mas completo
                let api_route = this.sucursales.find((x) => x.id_branch == this.acceso.sucursal).api_route;
                this.$storage.setStorageSync('api_route', api_route ? api_route : this.$config.api_route);
                this.$router.push('/');
            } catch (error) {
                console.log(error);
                this.$toast.add(new ErrorToast('No se pudo cargar el menu, revise con area de sistemas'));
            } finally {
                this.loading = false;
            }
        },
        async login() {
            this.loading = true;
            try {
                await this.usuario.login();
                let accesos = await this.usuario.access();
                this.accesos = accesos;
                accesos.forEach(x => {
                    if (!this.empresas.find(y => y.id_company == x.id_company)) {
                        this.empresas.push(x);
                    }
                });
                this.dialogAcceso = true;
            } catch (error) {
                this.$toast.add(new ErrorToast('No se pudo validar el usuario'));
            } finally {
                this.loading = false;
            }
        },
    },
    created() {
        this.app_creator = this.$config.app_creator;
        console.log('Version en ejecucion', process.env.VUE_APP_VERSION)
        if (this.$storage.hasKey('scheme')) {
            let scheme = this.$storage.getStorageSync('scheme');
            this.changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
            this.changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
        }
    },
    computed: {
        app_name() {
            return process.env.VUE_APP_NAME;
        },
        app_client() {
            return process.env.VUE_APP_CLIENT;
        },
        loginColor() {
            if (this.$appState.colorScheme === 'light') return 'ondark';
            return 'onlight';
        },
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        },
    },
};
</script>
